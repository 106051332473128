<template>
  <v-container fluid>
    <v-row class="d-flex mb-2 pt-6" no-gutters>
      <p>
        <strong>{{ $_strings.contract.label.DOWNLOAD_CONTRACT }}</strong>
      </p>
    </v-row>
    <v-card class="pt-8 pl-2 pr-2 pb-4">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="submit"
      >
        <v-row>
          <v-col class="pb-0" cols="12" sm="3" md="2">
            <span class="subtitle-1">{{ $_strings.contract.header.COMPANY_NAME}} <span class="red--text">*</span></span>
          </v-col>
          <v-col cols="12" class="pt-0" sm="9" md="10">
            <v-autocomplete
              dense
              outlined
              item-text="companyName"
              item-value="companyId"
              placeholder="Nama Perusahaan"
              :rules="rules.companyNameRules"
              :items="itemsCompanyList"
              v-model="form.companyId"
              @change="($event) => setForm($event)"
              :loading="isLoadingCompanyList"
              @input.native="($event) => fetchCompanyList($event.target.value)"
              clearable
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" sm="3" md="2">
            <span class="subtitle-1">{{ $_strings.contract.label.MOU_NUMBER }}<span class="red--text">*</span></span>
          </v-col>
          <v-col cols="12" class="pt-0" sm="9" md="10">
            <v-text-field
              :value="form.mouNo"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.contract.label.MOU_NUMBER)]"
              dense
              outlined
              readonly
              placeholder="Nomor MoU"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" sm="3" md="2">
            <span class="subtitle-1">{{ $_strings.contract.header.CONTRACT_NUMBER}} <span class="red--text">*</span></span>
          </v-col>
          <v-col cols="12" class="pt-0" sm="9" md="10">
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Nomor Kontrak"
              v-model="form.contractNo"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.contract.header.CONTRACT_NUMBER)]"
              readonly
              aria-hidden="true"
              required
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0" cols="12" sm="3" md="2">
            <span class="subtitle-1">{{ $_strings.contract.label.EFFECTIVE_PERIOD}} <span class="red--text">*</span></span>
          </v-col>
          <v-col md="3">
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Tanggal"
              :value="form.periodStart ? dayjs(form.periodStart).format('DD/MM/YYYY') : null"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.contract.label.EFFECTIVE_PERIOD)]"
              readonly
            >
            </v-text-field>
          </v-col>
          <v-col cols="auto">
            <span class="subtitle-1">{{ $_strings.contract.label.UNTIL}} <span class="red--text">*</span></span>
          </v-col>
          <v-col md="3">
            <v-text-field
              height="30"
              outlined
              dense
              placeholder="Tanggal"
              :value="form.periodEnd ? dayjs(form.periodEnd).format('DD/MM/YYYY') : null"
              :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.contract.label.UNTIL)]"
              readonly
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3" md="2">
            <span class="subtitle-1">{{ $_strings.contract.label.SIGNERS_NAME}}</span>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              v-model="form.signers.signerName"
              :rules="checkSigner ? rules.signerName : []"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="auto" >
            <span class="subtitle-1">{{$_strings.contract.label.SIGNING_POSITION}}</span>
          </v-col>
          <v-col cols="12" sm="3" >
            <v-text-field
              outlined
              dense
              v-model="form.signers.signerPosition"
              :rules="checkSigner ? rules.signerPosition : []"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- BUTTONS -->
        <v-row class="d-flex justify-end mt-12 mr-5 mb-2">
          <v-btn
            color="white"
            to="/main/kontrak-shipper"
          >
            {{$_strings.contract.label.CLOSE}}
          </v-btn>
          <v-btn color="primary" class="ml-4" type="submit"
          >
            {{$_strings.contract.label.DOWNLOAD_CONTRACT}}
          </v-btn>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';

export default {
  mounted() {
    this.fetchCompanyList();
  },
  data() {
    return {
      rules: {
        contractFormatType: [(v) => !!v || 'Kontrak format wajib diisi'],
        companyNameRules: [(v) => !!v || 'Nama perusahaan wajib diisi'],
        signerName: [(v) => !!v || 'Nama Penandatangan wajib diisi'],
        signerPosition: [(v) => !!v || 'Jabatan Penandatangan wajib diisi'],
      },
      itemsCompanyList: [],
      isLoadingCompanyList: false,
      form: {
        companyId: null,
        companyName: null,
        contractNo: null,
        mouId: null,
        mouNo: null,
        periodStart: null,
        periodEnd: null,
        signers: {
          signerName: '',
          signerPosition: '',
        },
      },
    };
  },
  computed: {
    checkSigner() {
      let required = false;
      Object.keys(this.form.signers).forEach((sign) => {
        if (this.form.signers[sign]) {
          required = true;
        }
      });
      return required;
    },
    contractBy() {
      const [, , path] = this.$route.path.split('/');
      return path;
    },
  },
  methods: {
    dayjs,
    serviceFetchMou(companyId) {
      if (this.contractBy === 'kontrak-shipper') {
        return this.$_services.contract.fetchMouShipperId({ companyId });
      }
      return this.$_services.contract.fetchMou3PLId({ companyId });
    },
    async fetchCompanyList(companyName = '') {
      try {
        this.isLoadingCompanyList = true;
        const type = this.contractBy === 'kontrak-shipper' ? 'shipper' : 'transporter';
        const result = await this.$_services.contract.fetchCompanyListContract(companyName, type);
        this.itemsCompanyList = [...this.itemsCompanyList, ...result.data.contents];
      } finally {
        this.isLoadingCompanyList = false;
      }
    },
    async setForm(companyId) {
      if (!companyId) {
        this.form = {
          ...this.form,
          contractNo: null,
          mouId: null,
          mouNo: null,
          periodStart: null,
          periodEnd: null,
        };
        return;
      }
      try {
        this.$root.$loading.show();
        const result = await this.serviceFetchMou(companyId);
        const { data } = result;
        if (data) {
          this.form = {
            ...this.form,
            contractNo: this.generateContractNumber(data.mouNo),
            mouId: data.id,
            mouNo: data.mouNo,
            periodStart: data.mouPeriodStart,
            periodEnd: data.mouPeriodEnd,
          };
        }
      } catch {
        this.form = {
          companyId: null,
          companyName: null,
          contractNo: null,
          mouId: null,
          mouNo: null,
          periodStart: null,
          periodEnd: null,
          signers: {
            signerName: '',
            signerPosition: '',
          },
        };
      } finally {
        this.$root.$loading.hide();
      }
    },
    generateContractNumber(val) {
      const mouArray = val.split('/');
      mouArray[1] = 'LGL';
      const result = `DRAFT-${mouArray.join('/')}`;
      return result;
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let signers = null;
      if (this.checkSigner) {
        signers = this.form.signers;
      }
      const payload = {
        ...this.form,
        signers,
      };
      this.$root.$loading.show();
      this.$_services.contract.submitTemplateCreation(payload).then((res) => {
        this.downloadTemplate(res.data.url);
        this.$dialog.notify.success('Berhasil');
      }).finally(() => {
        this.$root.$loading.hide();
      });
    },
    downloadTemplate(url) {
      window.open(url);
    },
  },
};
</script>
